import './crtoverlay.scss';

//CREDIT LUCAS BEBBER http://lbebber.github.io/public/

const CrtOverlay = () => {


    return (
        <div className="crtOverlay-mainContainer crt">
            <div className="crtOver"></div>
        </div>
    )

}

export default CrtOverlay;