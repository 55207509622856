import React, { useState, useRef, useEffect, useInterval } from 'react';
import './musicplayercontainer.scss';

const MusicPlayerContainer = (props) => {
    const audioContextRef = useRef(null);
    const sourceRef = useRef(null);
    const distortionRef = useRef(null);
    const biquadFilterRef = useRef(null);
    const gainNodeRef = useRef(null);
    const canvasRef = useRef(null);
    const analyserRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [byteData, setByteData] = useState([]);

    useEffect(() => {
        // Set up the audio context and nodes when the component mounts
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 512;

        // Set up nodes
        distortionRef.current = audioContextRef.current.createWaveShaper();
        biquadFilterRef.current = audioContextRef.current.createBiquadFilter();
        gainNodeRef.current = audioContextRef.current.createGain();

        return () => {
            // Clean up the audio context when the component unmounts
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        // Automatically start playing when a new URL is provided
        if (props.audioPath) {
            playAudio();
        }

        // Stop audio if the component unmounts
        return () => {
            stopAudio();
        };
    }, [props.audioPath]);

    const playAudio = async () => {
        try {
            const response = await fetch(props.audioPath);
            const arrayBuffer = await response.arrayBuffer();
            audioContextRef.current.decodeAudioData(arrayBuffer, (buffer) => {
                if (sourceRef.current) {
                    sourceRef.current.disconnect();
                }

                sourceRef.current = audioContextRef.current.createBufferSource();
                sourceRef.current.buffer = buffer;

                // Connect nodes
                sourceRef.current.connect(distortionRef.current);
                distortionRef.current.connect(biquadFilterRef.current);
                biquadFilterRef.current.connect(gainNodeRef.current);
                gainNodeRef.current.connect(audioContextRef.current.destination);
                analyserRef.current.connect(audioContextRef.current.destination);

                sourceRef.current.start();
                setIsPlaying(true);

                drawVisualization();

            });
        } catch (error) {
            console.error('Error fetching or decoding audio:', error);
        }
    };

    const stopAudio = () => {
        if (sourceRef.current) {
            sourceRef.current.stop();
            sourceRef.current.disconnect();
            setIsPlaying(false);
        }
    };

    const drawVisualization = () => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
      
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;
      
        let lastDrawTime = performance.now();
        const desiredFPS = 30; // Target FPS
        const interval = 1000 / desiredFPS;
      
        function draw() {
          requestAnimationFrame(draw);
      
          const now = performance.now();
          const elapsed = now - lastDrawTime;
      
          if (elapsed > interval) {
            lastDrawTime = now - (elapsed % interval);
      
            analyserRef.current.getByteTimeDomainData(dataArray);
            console.log(dataArray)
            canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
      
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
      
            canvasCtx.beginPath();
      
            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;
      
            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = (v * HEIGHT) / 2;
      
              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }
      
              x += sliceWidth;
            }
      
            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          }
        }
      
        draw();
      };

    return (
        <div>
            <canvas ref={canvasRef} width="800" height="200" />
            <button onClick={isPlaying ? stopAudio : playAudio}>
                {isPlaying ? 'Stop' : 'Play'}
            </button>
        </div>
    );
};

export default MusicPlayerContainer;