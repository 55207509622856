import './shortCut.scss';
import document from '../../../src/assets/documentIcon.png';
import documentClean from '../../../src/assets/documentIcon19845Black.png';
// import linkIcon from '../../../src/assets/linkIconWHITE.png';
import linkIcon from '../../../src/assets/linkIconBlack.png';
import appIcon from '../../../src/assets/terminalAsset Black.png';
import { useEffect } from 'react';

const ShortCut = (props) => {

    let shortCutText = props?.shortCutData?.item?.itemIconText;

    let shortCutImgLink = props?.shortCutData?.item?.itemIconPath;
    
    //TODO: REFACTOR, THIS IS UGLY
    if(shortCutImgLink === 'documentIconClean') {
        shortCutImgLink = documentClean;
    }
    else if(shortCutImgLink === 'linkIcon') {
        shortCutImgLink = linkIcon;
    }
    else if(shortCutImgLink === 'appIcon') {
        shortCutImgLink = appIcon;
    }
    else if(shortCutImgLink === 'terminalIcon') {
        shortCutImgLink = appIcon;
    }


    return ( 
        <div className='shortCut-container' id='shortCut-container' onClick={props?.anOnclickEvent}>
            {/* <img className='shortCut-icon' src={shortCutImgLink} /> */}
            <img className='shortCut-icon' src={shortCutImgLink}
                style={{tintColor: "var(--primary-color"}} />
            {/* <LinkLogo fill={'var(--primary-color)'} stroke={'var(--primary-color)'}/> */}
            <div className='shortCut-text'>
                {shortCutText}
            </div>
        </div>
    )
}

export default ShortCut;