import './loadingBar.scss';


//350 across
const LoadingBar = () => {



}

export default LoadingBar;