import './App.scss';
import Main from './pages/main/main';
import { TerminalContextProvider } from "react-terminal";
import { ThemeProvider } from './modules/Theme/ThemeContext';

function App() {
  return (
    <TerminalContextProvider>
      <ThemeProvider>
      <div className="App">
        <Main />
      </div>
      </ThemeProvider>
    </TerminalContextProvider>
  );
}

export default App;
