import './main.scss';
import System from '../../modules/system/system';
import CrtOverlay from '../../modules/crtoverlay/crtoverlay';

const Main = () => {

    return (
        <div className="main-container">
            <div className="main-outerLines">
                <CrtOverlay />
                <System />
            </div>
        </div>
    )

}

export default Main;