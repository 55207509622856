import './system.scss';
import Buddies from '../buddies/buddies';
import usePortal from 'react-useportal';

//THEME IMAGES
import cat from '../../../../port/src/assets/default.png';
import bachira from '../../../../port/src/assets/bachira.png';
import karma from '../../../../port/src/assets/sdfSd.png';
import tomioka from '../../../../port/src/assets/tomi.png';
import milim from '../../../../port/src/assets/milim.png';


import LoadingBar from '../loadingBar/loadingBar';
import TopMenu from '../topMenu/topMenu';
import BottomMenu from '../bottomMenu/bottomMenu';
import ShortCut from '../shortCut/shortCut';
import { useEffect, useState } from 'react';
import siteContent from '../../assets/siteContent.json';
import { useTheme } from '../Theme/ThemeContext';
var randomWords = require('random-words');

const System = (props) => {

    const { currentTheme } = useTheme();

    const { Portal } = usePortal({
        bindTo: document.getElementById('system-mainContainer'),
    });

    const shortCuts = [];
    const [buddiesList, setBuddiesList] = useState([]);
    const [currentImage, setCurrentImage] = useState(cat);
    const [numWindows, setNumWindows] = useState(0);

    const getNextBuddyZIndex = () => {
        if(buddiesList.length > 0) {
            let max = 0;
            buddiesList.map((buddies) => {
                if(max < buddies.zIndex) {
                    max = buddies.zIndex;
                }
            });
            return max = max + 1;
        }
        else {
            return 90;
        }
    }

    const closeBuddyEventMain = (name) => {
        let tempList = [];
        buddiesList.map((buddy) => {
            if(buddy.name !== name) {
                tempList.push(buddy);
            }
        });
        setBuddiesList(tempList);
    }

    const closeBuddyEvent = (name) => {
        closeBuddyEventMain(name);
    }

    const openBuddyEvent = (item) => {
        let nextZIndex = getNextBuddyZIndex();
        let aList = buddiesList;
        setBuddiesList(
            aList.concat(
                {name: randomWords(), key: buddiesList.length, indexer: buddiesList.length,  
                zIndex: nextZIndex, numBuddies: numWindows, title: item?.itemName, content: item?.itemContent,
                link: item?.itemLink }
            )
        );

    };

    siteContent && siteContent.contentItems.map((content) => {
        if(content.itemType === "link") {
            shortCuts.push({ "item": content });
        }
        else if(content.itemType === "text") {
            shortCuts.push({ "item": content });
        }
        else if(content.itemType === "app") {
            shortCuts.push({ "item": content });
        }
    });

    // POINTING TO SYSTEM
        // EVENT TO CLOSE 
        // ONCLICK EVENT TO UPDATE Z INDEX

    // BUDDY INTERNAL 
        // MAX SIZE EVENT
        // RETURN TO PREVIOUS SIZE EVENT

    const updateBuddyZIndexEvent = (name) => {
        //setBuddiesList(buddiesList.filter(buddy => buddy.name !== name));
        let tempList = [];
        buddiesList.map((buddy) => {
            if(buddy.name === name) {
                buddy.zIndex = getNextBuddyZIndex();
            }
            tempList.push(buddy);
        });
        setBuddiesList(tempList);
    }


    const getCurrentImage = () => {
        if(currentTheme === "Default") {
            setCurrentImage(cat);
        }
        else if(currentTheme === "Karma") {
            setCurrentImage("");
        }
        else if(currentTheme === "Bachira") {
            setCurrentImage(bachira);
        }
        else if(currentTheme === "Milim") {
            setCurrentImage(milim);
        }
        else if(currentTheme === "Tomioka") {
            setCurrentImage(tomioka);
        }
        else {
            setCurrentImage(cat);
        }
    }

    useEffect(() => {
        getCurrentImage();
    }, [currentTheme]);

    return (
        <div className="system-mainContainer" id="system-mainContainer">
            <TopMenu />
            <div className='system-shortcuts'>
                {shortCuts.map((shortCut, key) => {
                    if(shortCut.item.itemType === "link") {
                        const event = () => window.open(shortCut.item.itemContent, '_blank');
                        return <ShortCut key={key} shortCutData={shortCut} anOnclickEvent={event}/>
                    }
                    return <ShortCut key={key} 
                                     shortCutData={shortCut} 
                                     anOnclickEvent={() => openBuddyEvent(shortCut.item)}
                                     />
                })}
            </div>
            <div className='system-buddiesContainer'>
                {buddiesList.map(buddies => {
                    return(
                        <Buddies name={buddies.name} 
                            key={buddies.key} 
                            indexer={buddies.indexer} 
                            content={buddies.content} 
                            zIndex={buddies.zIndex} 
                            numBuddies={buddies.numBuddies}
                            closeBuddy={(aName) => closeBuddyEvent(aName)}
                            updateBuddyZIndex={(aName) => updateBuddyZIndexEvent(aName)}
                            title={buddies.title}
                            link={buddies?.link}
                        /> 
                    )
                })}
            </div>
            <div className='system-backgroundContainer'>
                <img className='system-backgroundImage' 
                src={currentImage} />
                <div className='system-backgroundLoadingBarContainer'>
                    <LoadingBar />
                </div>
            </div>
            <BottomMenu />
        </div>
    )

}

export default System;