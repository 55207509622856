import React, { useState, useEffect } from 'react';

const Time = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

//   const options = {
//     weekday: 'long',
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     timeZoneName: 'short'
//   };

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };

  const dateTimeString = dateTime.toLocaleString('en-US', options);

  return (
    <div className="dateTimeBox">
      <p>{dateTimeString}</p>
    </div>
  );
}

export default Time;