import { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState("Default");
    
  const toggleTheme = (tryGetTheme) => {
      console.log(tryGetTheme)
        document.documentElement.classList.remove(currentTheme.toLowerCase() + "-theme");
        //const nextTheme = getNextTheme(currentTheme);
        document.documentElement.classList.add(tryGetTheme.toLowerCase() + "-theme");
        setCurrentTheme(tryGetTheme);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, currentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};