import React, { useState } from 'react';
import './bottomMenu.scss';
import { useTheme } from '../Theme/ThemeContext';

const BottomMenu = () => {

    const [selectedValue, setSelectedValue] = useState('');

    const options = ['Default', 'Bachira', 'Milim', 'Karma', 'Tomioka'];

    const { toggleTheme } = useTheme();

    const handleSelect = (value) => {
        setSelectedValue(value);
        //onSelect(value);
        console.log(value);
        toggleTheme(value);
    };

    return (
        <div className='bottomMenu-container'>
            <div className='bottomMenu-leftContent'>
                <div className="dropUp">
                    <button className="theme-button">{selectedValue || 'Select a Theme'}</button>
                    <div className="dropUpContent">
                        {options.map((option, index) => (
                        <a key={index} onClick={() => handleSelect(option)}>
                            {option}
                        </a>
                        ))}
                    </div>
                </div>
            </div>
            <div className='bottomMenu-rightContent'>
                
            </div>
        </div>
    )

}

export default BottomMenu;